import React from 'react';
import type PostListProGallery from '@app/external/common/components/post-list-pro-gallery';
import type PostListMasonry from '@app/external/feed-page/components/post-list/post-list-masonry';
import type PostListMobile from '@app/external/feed-page/components/post-list/post-list-mobile';
import type PostListSimple from '@app/external/feed-page/components/post-list/post-list-simple';
import type PostListItem from '@app/external/feed-page/components/post-list-item';
import type SideBySide from '@app/external/feed-page/components/post-list-item/mobile/side-by-side';
import type TextOnImage from '@app/external/feed-page/components/post-list-item/mobile/text-on-image';
import type PostPagePost from '@app/external/post-page/containers/post-page/post-page-post';

export type ComponentsContextValue = {
  PostListProGallery: typeof PostListProGallery;
  PostListSimple: typeof PostListSimple;
  PostListMasonry: typeof PostListMasonry;
  PostListMobile: typeof PostListMobile;
  PostListItem: typeof PostListItem;
  Post: typeof PostPagePost;
  SideBySideMobile: typeof SideBySide;
  TextOnImageMobile: typeof TextOnImage;
};

export type ComponentKey = keyof ComponentsContextValue;

const Empty: React.FC = () => null;

export const ComponentsContext = React.createContext<ComponentsContextValue>({
  PostListProGallery: Empty as any,
  PostListSimple: Empty as any,
  PostListMasonry: Empty as any,
  PostListMobile: Empty as any,
  PostListItem: Empty as any,
  Post: Empty as any,
  SideBySideMobile: Empty as any,
  TextOnImageMobile: Empty as any,
});
