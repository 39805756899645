import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import createHocName from '../../services/create-hoc-name';
import { ComponentsContext, type ComponentsContextValue } from './components-context';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { useComponents } from './use-components';

export type WithComponents = ComponentsContextValue;

/** @deprecated use {@link useComponents} hook instead */
function withComponents<T extends WithComponents = WithComponents>(WrappedComponent: React.FC<T>) {
  const WithComponents = (props: T) => (
    <ComponentsContext.Consumer>
      {(components) => <WrappedComponent {...components} {...props} />}
    </ComponentsContext.Consumer>
  );

  WithComponents.displayName = createHocName('WithComponents', WrappedComponent);

  hoistNonReactStatics(WithComponents, WrappedComponent);

  return WithComponents;
}

export default withComponents;
