import React from 'react';
import classNames from 'classnames';
import { Image } from '@wix/image';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import {
  getImageUri,
  LAYOUT_NAME_ONE_COLUMN_MOBILE,
  type LayoutName,
  type MediaPlatformImage,
} from '@wix/communities-blog-client-common';
import { useResponsiveContext } from '../responsive-listener/responsive-context';
import { useImageDimensions } from './hooks/use-image-dimensions';
import styles from './post-list-item-image.scss';

type PostListItemImageMobileProps = {
  image: Omit<MediaPlatformImage, 'width' | 'height'> &
    Required<Pick<MediaPlatformImage, 'width' | 'height'>>;
  url?: string;
  isPublic?: boolean;
  width: number;
  height: number;
  layoutName: LayoutName;
};

export const PostListItemImageMobile: React.FC<PostListItemImageMobileProps> = ({
  image,
  url,
  isPublic,
  width,
  height,
  layoutName,
}) => {
  const { rootWidth } = useResponsiveContext();
  const { dimensions: widgetDimensions } = useEnvironment();

  const dimensions = useImageDimensions({
    container: { width, height },
    component: { width: rootWidth ?? widgetDimensions.width },
    layoutName,
  });

  const uri = getImageUri(image);
  const aspectRatio = (image.height as number) / (image.width as number);
  const targetWidth = dimensions.width;
  const targetHeight = dimensions.height || Math.floor(targetWidth * aspectRatio);

  const style = {
    aspectRatio:
      layoutName === LAYOUT_NAME_ONE_COLUMN_MOBILE
        ? ` ${image.width} / ${image.height}`
        : ` ${targetWidth} / ${targetHeight}`,
  };

  return (
    <div className={classNames(styles.container, styles.mobile)} style={style}>
      {uri ? (
        <Image
          alt=""
          className={styles.image}
          uri={uri}
          displayMode="fill"
          width={image.width as number}
          height={image.height as number}
          targetWidth={targetWidth}
          targetHeight={targetHeight}
          data-hook="post-list-image"
          socialAttrs={isPublic ? { 'data-pin-url': url } : { 'data-pin-nopin': true }}
        />
      ) : undefined}
    </div>
  );
};

export default PostListItemImageMobile;
