import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import EmptyStates from '../../components/empty-states';
import { useSelector } from '../../components/runtime-context';
import { getQueryLocale } from '../../selectors/locale-selectors';
import { isEditor } from '../../store/basic-params/basic-params-selectors';

type Props = React.ComponentProps<typeof EmptyStates>;

const NoPostsFeed: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const state = useSelector((s) => ({
    isMultilingual: Boolean(getQueryLocale(s)),
    isEditor: isEditor(s),
  }));
  const title = state.isMultilingual
    ? t('no-posts-feed.no-published-posts-multilingual')
    : state.isEditor
    ? t('no-posts-feed.no-posts-to-show')
    : t('no-posts-feed.on-the-way');

  const content = state.isEditor
    ? t('no-posts-feed.publish-posts-to-see-them')
    : t('no-posts-feed.stay-tuned');

  return <EmptyStates {...props} title={title} content={content} />;
};

export default NoPostsFeed;
