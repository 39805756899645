import React from 'react';
import { SlotsPlaceholder } from '@wix/widget-plugins-ooi';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { createPageUrl, SECTION_HOMEPAGE } from '@wix/communities-blog-client-common';
import stylesParams from '@app/components/Blog/stylesParams';
import { PostTitleTagProvider } from '@app/external/common/context/post-title-tag-context';
import { ABOVE_POST_LIST } from '../../../../constants/ooi-slots';
import AnimatedLoader from '../../../common/components/animated-loader';
import Page from '../../../common/components/page';
import { useSelector } from '../../../common/components/runtime-context';
import Pagination from '../../../common/containers/pagination';
import {
  getIsCreatedWithResponsiveEditor,
  getIsFeaturedPostBannerEnabled,
  getLayoutPostSize,
} from '../../../common/selectors/app-settings-selectors';
import { handleNavigation } from '../../../common/services/navigation';
import { isSite as getIsSite } from '../../../common/store/basic-params/basic-params-selectors';
import { getIsFeedPostsLoaded } from '../../../common/store/is-loaded/is-loaded-selectors';
import { getSectionUrl } from '../../../common/store/topology/topology-selectors';
import { type RouteHomeParams } from '../../constants/routes';
import usePaginationSettings from '../../hoc/use-pagination-settings';
import FeaturedPostBanner from '../featured-post-banner';
import FeedPagePosts from './feed-page-posts';

type Props = {
  params: RouteHomeParams;
  isBlocked?: boolean;
};

export const FeedPage = (props: Props) => {
  const pagination = usePaginationSettings(SECTION_HOMEPAGE, props.params.page);
  const isBannerEnabled = useSelector(getIsFeaturedPostBannerEnabled);
  const environment = useEnvironment();
  const isLoaded = useSelector(getIsFeedPostsLoaded);
  const isSite = useSelector(getIsSite);
  const sectionUrl = useSelector(getSectionUrl);
  const layoutPostSize = useSelector((state) => getLayoutPostSize({ state }));
  const createdWithResponsiveEditor = useSelector(getIsCreatedWithResponsiveEditor);
  const hostDimensions = environment.dimensions;
  const stretchedToFullWidth = !hostDimensions.width;
  const stretchedByHand = hostDimensions.width > layoutPostSize;

  return (
    <PostTitleTagProvider titleFontParam={stylesParams.cssTitleFontAllPosts} fallbackTagName="h2">
      <Page noSpacing>
        {isBannerEnabled && <FeaturedPostBanner />}
        {createdWithResponsiveEditor ? (
          <SlotsPlaceholder slotId={ABOVE_POST_LIST} />
        ) : (
          <div
            style={{
              width: stretchedToFullWidth
                ? layoutPostSize
                : stretchedByHand
                ? layoutPostSize
                : hostDimensions.width,
              margin: '0 auto',
            }}
          >
            <SlotsPlaceholder slotId={ABOVE_POST_LIST} />
          </div>
        )}
        <AnimatedLoader isLoading={!isLoaded}>
          <FeedPagePosts {...props} />
        </AnimatedLoader>
        {pagination.showPagination ? (
          <Pagination
            dataHook="feed-pagination-container"
            page={pagination.page}
            pageSize={pagination.pageSize}
            entityCount={pagination.entityCount}
            createPageUrl={(page) => createPageUrl(page, sectionUrl)}
            handleNavigation={handleNavigation('/', isSite)}
          />
        ) : null}
      </Page>
    </PostTitleTagProvider>
  );
};

export default FeedPage;
