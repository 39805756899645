import React from 'react';
import { useMatchedRoute } from '../../../common/router/hooks';
import { ROUTE_COMPONENT_MAP } from './route-component-map';

const Router: React.FC = (props) => {
  const { route, params } = useMatchedRoute();

  if (!route || !(route in ROUTE_COMPONENT_MAP)) {
    throw new Error(`Route "${route}" - is not implemented`);
  }

  const Component = ROUTE_COMPONENT_MAP[route as keyof typeof ROUTE_COMPONENT_MAP];

  return <Component params={params} {...props} />;
};

export default Router;
