import React, { useEffect } from 'react';
import { withSlotsPlaceholders } from '@wix/widget-plugins-ooi';
import loadable from '@wix/yoshi-flow-editor/loadable';
import { useBlogNotifications } from '@app/external/common/hooks/use-ws-notifications';
import { FullPostLayoutOverridesProvider } from '@app/external/post-page/containers/post-page/feed-overrides-context';
import type PostPagePost from '@app/external/post-page/containers/post-page/post-page-post';
import ComponentsProvider from '../../../common/components/components-provider';
import PermissionsProvider from '../../../common/components/permissions-provider';
import ResponsiveListener from '../../../common/components/responsive-listener/responsive-listener';
import { useSelector } from '../../../common/components/runtime-context';
import { withReduxStore } from '../../../common/components/runtime-context/with-redux-store';
import { useI18n } from '../../../common/hooks/use-i18n';
import {
  getIsCreatedWithResponsiveEditor,
  getPinterestEnabled,
} from '../../../common/selectors/app-settings-selectors';
import pinterest from '../../../common/services/pinterest';
import {
  getIsMobile,
  getLanguage,
  isEditor as getIsEditor,
} from '../../../common/store/basic-params/basic-params-selectors';
import PostListMasonry from '../../components/post-list/post-list-masonry';
import PostListMobile from '../../components/post-list/post-list-mobile';
import PostListSimple from '../../components/post-list/post-list-simple';
import PostListItem from '../../components/post-list-item';
import SideBySideMobile from '../../components/post-list-item/mobile/side-by-side';
import TextOnImageMobile from '../../components/post-list-item/mobile/text-on-image';
import Router from '../router';
import styles from './app-root.scss';

const LazyPostPagePost = loadable(
  () =>
    import(
      /* webpackChunkName: "full-post-layout" */ '@app/external/post-page/containers/post-page/post-page-post'
    ),
) as typeof PostPagePost;

const AppRoot: React.FC = () => {
  useAutoHeight();
  useInitPinterest();
  useI18n();
  useBlogNotifications();

  return (
    <ResponsiveListener dataHook="feed-page-root" className={styles.root}>
      <PermissionsProvider>
        <FullPostLayoutOverridesProvider>
          <ComponentsProvider
            PostListProGallery={LazyPostListProGallery}
            PostListSimple={PostListSimple}
            PostListMasonry={PostListMasonry}
            PostListMobile={PostListMobile}
            PostListItem={PostListItem}
            Post={LazyPostPagePost}
            SideBySideMobile={SideBySideMobile}
            TextOnImageMobile={TextOnImageMobile}
          >
            <Router />
          </ComponentsProvider>
        </FullPostLayoutOverridesProvider>
      </PermissionsProvider>
    </ResponsiveListener>
  );
};

function useInitPinterest() {
  const language = useSelector(getLanguage);
  const isMobile = useSelector(getIsMobile);
  const isPinterestEnabled = useSelector(getPinterestEnabled);

  useEffect(() => {
    pinterest.init({ language, isMobile, isPinterestEnabled });

    return () => {
      pinterest.removeEventListener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

function useAutoHeight() {
  const shouldSetAutoHeight = useSelector(
    (state) => getIsEditor(state) && getIsCreatedWithResponsiveEditor(state),
  );

  useEffect(() => {
    if (shouldSetAutoHeight) {
      document.body.style.height = 'auto';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

const LazyPostListProGallery = loadable(
  () =>
    import(
      /* webpackChunkName: "post-list-pro-gallery" */ '../../../common/components/post-list-pro-gallery'
    ),
);

export default withSlotsPlaceholders(withReduxStore(AppRoot));
