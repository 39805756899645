import React, { Component } from 'react';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import { resolveId } from '@wix/communities-blog-client-common';

import Pagination from '../../../common/components/pagination';
import PopoverRoot from '../../../common/components/popovers/popover-root';
import withCardBorderWidth from '../../../common/hoc/with-card-border-width';
import PostSearchListItem from '../post-search-list-item';
import styles from './search-results-list.scss';

const POST = 'post';
const ITEMS_PER_PAGE = 20;

class SearchResultsDesktopList extends Component {
  state = {
    page: 1,
    from: 0,
    to: ITEMS_PER_PAGE,
  };

  handlePageChange = ({ page, from, to }) => this.setState({ page, from, to });

  renderItem(post, query, onPostLikeClick) {
    switch (post.type) {
      case POST:
        return (
          <PostSearchListItem
            key={resolveId(post)}
            post={post}
            query={query}
            onLikeClick={onPostLikeClick}
            layoutType={this.props.layoutType}
          />
        );
      default:
        return null;
    }
  }

  render() {
    const { posts, query, onPostLikeClick, borderWidth } = this.props;
    const { page, from, to } = this.state;
    const style = {
      borderWidth,
      marginTop: -borderWidth,
    };
    return (
      <div className={classNames(styles.container, 'search-results-list')}>
        <div className={classNames(styles.wrapper, 'blog-card-border-color')} style={style}>
          {posts.slice(from, to).map((post) => this.renderItem(post, query, onPostLikeClick))}
          <Pagination
            className={classNames(styles.pagination, 'blog-card-background-color')}
            page={page}
            entityCount={posts.length}
            pageSize={ITEMS_PER_PAGE}
            onChange={this.handlePageChange}
          />
          <PopoverRoot />
        </div>
      </div>
    );
  }
}

SearchResultsDesktopList.propTypes = {
  posts: PropTypes.array.isRequired,
  borderWidth: PropTypes.number.isRequired,
  query: PropTypes.string,
  onPostLikeClick: PropTypes.func,
  layoutType: PropTypes.number.isRequired,
};

export default flowRight(withCardBorderWidth)(SearchResultsDesktopList);
