import { getLastPage, PaginationType, type Section } from '@wix/communities-blog-client-common';
import { useSelector } from '../../common/components/runtime-context';

import {
  getCurrentPage,
  getEntityCount,
  getLastPageIndex,
  getPaginationPageSize,
  getPaginationType,
} from '../../common/selectors/pagination-selectors';

export default function usePaginationSettings(section: Section, pageProp?: string) {
  const paginationType = useSelector((state) => getPaginationType(state, section));
  const showPagination = paginationType === PaginationType.MultiplePages;

  const currentPage = useSelector((state) => getCurrentPage(state, 'posts'));
  const lastPageIndex = useSelector((state) => getLastPageIndex(state, 'posts'));
  const pageSize = useSelector((state) => getPaginationPageSize(state, section));
  const entityCount = useSelector((state) => getEntityCount(state, 'posts'));

  const page = showPagination ? Number(pageProp ?? currentPage) : lastPageIndex;

  const lastPage = getLastPage(entityCount, pageSize);
  return {
    paginationType,
    page,
    pageSize,
    lastPage,
    isValidPage: page === 1 || (page > 1 && page <= lastPage),
    entityCount,
    showPagination,
  };
}
