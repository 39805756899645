import './styles.scss';

import React, { useCallback, useEffect, useRef } from 'react';
import {
  feedWidgetStyleParams,
  isLayoutProGallery,
  SECTION_HOMEPAGE,
} from '@wix/communities-blog-client-common';
import { useComponents } from '../../../common/components/components-provider/use-components';
import { useActions, useSelector } from '../../../common/components/runtime-context';

import NoPostsFeed from '../../../common/containers/no-posts-feed';
import useLayoutProps from '../../../common/hooks/use-layout-props';
import usePermissions from '../../../common/hooks/use-permissions';
import { getAppSettingsValue } from '../../../common/selectors/app-settings-base-selectors';
import { getCanSeeMoreButton } from '../../../common/selectors/app-settings-selectors';
import { isCreatePostButtonVisible } from '../../../common/selectors/blog-menu-button-selectors';
import { isPostContentRequired } from '../../../common/selectors/layout-selectors';
import { getFeedPosts, getFeedPostsByPage } from '../../../common/selectors/post-selectors';
import { getIsMobile } from '../../../common/store/basic-params/basic-params-selectors';
import { getIsFeedPostsLoaded } from '../../../common/store/is-loaded/is-loaded-selectors';
import { getIsFeedPostsLoading } from '../../../common/store/is-loading/is-loading-selectors';
import PostList from '../../components/post-list';
import { type RouteHomeParams } from '../../constants/routes';
import usePaginationSettings from '../../hoc/use-pagination-settings';

type Props = {
  params: RouteHomeParams;
  isBlocked?: boolean;
};

const FeedPagePosts: React.FC<Props> = ({ isBlocked, params }) => {
  const pagination = usePaginationSettings(SECTION_HOMEPAGE, params.page);
  const { PostListProGallery } = useComponents();
  const layoutProps = useLayoutProps();
  const slice = useFeedPagePostsSlice(params.page);
  const loaders = useFeedLoader(isBlocked, params.page);

  if (slice.isLoaded && !slice.posts.length) {
    return <NoPostsFeed />;
  }

  if (layoutProps.layoutType && isLayoutProGallery(layoutProps.layoutType)) {
    return (
      <PostListProGallery
        pageStart={pagination.page + 1}
        entityCount={pagination.entityCount}
        loadMore={pagination.showPagination ? undefined : loaders.loadMore}
        allPosts={pagination.showPagination ? slice.currentPagePosts : slice.posts}
        currentPagePosts={slice.currentPagePosts}
        isLoading={slice.isLoading}
        showCreatePostAction={slice.showCreatePostAction}
        canSeeMoreButton={slice.canSeeMoreButton}
        layoutOptions={slice.layoutOptions}
        section={SECTION_HOMEPAGE}
      />
    );
  }

  return (
    <PostList
      pageStart={pagination.page + 1}
      entityCount={pagination.entityCount}
      page={pagination.page}
      pageSize={pagination.pageSize}
      loadRest={pagination.showPagination ? loaders.loadRest : undefined}
      loadMore={pagination.showPagination ? undefined : loaders.loadMore}
      allPosts={pagination.showPagination ? slice.currentPagePosts : slice.posts}
      currentPagePosts={slice.currentPagePosts}
      isLoading={slice.isLoading}
      showCreatePostAction={slice.showCreatePostAction}
    />
  );
};

function useFeedLoader(isBlocked: boolean | undefined, page: string | undefined) {
  const includeContent = useSelector((state) => isPostContentRequired(state));
  const pagination = usePaginationSettings(SECTION_HOMEPAGE, page);
  const prevPageSizeRef = useRef(pagination.pageSize);
  const { fetchFeedPostsWithAdapterPromisified } = useActions();

  const prevIncludeContentRef = useRef(includeContent);

  const fetchFeedPosts = useCallback(
    (pageToFetch = pagination.page) => {
      if (isBlocked) {
        return Promise.resolve();
      }

      return fetchFeedPostsWithAdapterPromisified({
        page: pageToFetch,
        includeContent: prevIncludeContentRef.current,
      });
    },
    [pagination.page, isBlocked, fetchFeedPostsWithAdapterPromisified],
  );

  const loadMore = useCallback(
    (pageToFetch: number) => fetchFeedPosts(pageToFetch),
    [fetchFeedPosts],
  );

  const loadRest = useCallback(
    () => fetchFeedPosts(pagination.page),
    [pagination.page, fetchFeedPosts],
  );

  useEffect(() => {
    if (prevPageSizeRef.current !== pagination.pageSize) {
      prevPageSizeRef.current = pagination.pageSize;
      prevIncludeContentRef.current = includeContent;

      fetchFeedPosts();
    }
  }, [fetchFeedPosts, pagination.pageSize, includeContent]);

  return { loadMore, loadRest, fetchFeedPosts };
}

function useFeedPagePostsSlice(page?: string) {
  const pagination = usePaginationSettings(SECTION_HOMEPAGE, page);
  const { canSee } = usePermissions();

  const currentPagePosts = useSelector((state) => getFeedPostsByPage(state, pagination.page));
  const showCreatePostAction = useSelector(
    (state) => getIsMobile(state) && isCreatePostButtonVisible(state, canSee),
  );
  const canSeeMoreButton = useSelector((state) => getCanSeeMoreButton(state, canSee));
  const isLoaded = useSelector(getIsFeedPostsLoaded);
  const isLoading = useSelector(getIsFeedPostsLoading);
  const posts = useSelector(getFeedPosts);

  const feedSliderShowArrows = useSelector((state) =>
    getAppSettingsValue({
      state,
      key: `style.booleans.${feedWidgetStyleParams.feedSliderShowArrows.key}`,
      fallback: feedWidgetStyleParams.feedSliderShowArrows.defaultValue,
    }),
  );
  const feedSliderArrowsSize = useSelector((state) =>
    getAppSettingsValue({
      state,
      key: `style.numbers.${feedWidgetStyleParams.feedSliderArrowsSize.key}`,
      fallback: feedWidgetStyleParams.feedSliderArrowsSize.defaultValue,
    }),
  );
  const feedSliderAutoSlide = useSelector((state) =>
    getAppSettingsValue({
      state,
      key: `style.booleans.${feedWidgetStyleParams.feedSliderAutoSlide.key}`,
      fallback: feedWidgetStyleParams.feedSliderAutoSlide.defaultValue,
    }),
  );
  const feedSliderPauseTime = useSelector((state) =>
    getAppSettingsValue({
      state,
      key: `style.numbers.${feedWidgetStyleParams.feedSliderPauseTime.key}`,
      fallback: feedWidgetStyleParams.feedSliderPauseTime.defaultValue,
    }),
  );
  const feedSliderArrowsPosition = useSelector((state) =>
    getAppSettingsValue({
      state,
      key: `style.numbers.${feedWidgetStyleParams.feedSliderArrowsPosition.key}`,
      fallback: feedWidgetStyleParams.feedSliderArrowsPosition.defaultValue,
    }),
  );
  const feedSliderArrowsColor = useSelector((state) =>
    getAppSettingsValue({
      state,
      key: `style.colors.${feedWidgetStyleParams.feedSliderArrowsColor.key}`,
      fallback: feedWidgetStyleParams.feedSliderArrowsColor.defaultValue,
    }),
  );
  const feedSliderLoop = useSelector((state) =>
    getAppSettingsValue({
      state,
      key: `style.booleans.${feedWidgetStyleParams.feedSliderLoop.key}`,
      fallback: feedWidgetStyleParams.feedSliderLoop.defaultValue,
    }),
  );

  const layoutOptions = {
    showArrows: feedSliderShowArrows,
    arrowsSize: feedSliderArrowsSize,
    autoSlide: feedSliderAutoSlide,
    pauseTime: feedSliderPauseTime,
    arrowsPosition: feedSliderArrowsPosition,
    arrowsColor: feedSliderArrowsColor,
    loop: feedSliderLoop,
  };

  return {
    canSeeMoreButton,
    currentPagePosts,
    isLoaded,
    isLoading,
    posts,
    showCreatePostAction,
    layoutOptions,
  };
}

export default FeedPagePosts;
