import { connect } from '../components/runtime-context';
import { getPostPageSectionUrl } from '../store/topology/topology-selectors';

const withSectionUrl = (WrappedComponent) => {
  const mapRuntimeToProps = (state) => ({
    sectionUrl: getPostPageSectionUrl(state),
  });

  return connect(mapRuntimeToProps)(WrappedComponent);
};

export default withSectionUrl;
