import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import {
  type Layout,
  POST_LIST_COMPONENT_MASONRY,
  type NormalizedCategory,
  type LayoutName,
} from '@wix/communities-blog-client-common';
import { useComponents } from '@app/external/common/components/components-provider/use-components';
import LoadMore from '@app/external/common/components/load-more';
import { type LoadMoreProps } from '@app/external/common/components/load-more/load-more';
import Loader from '@app/external/common/components/loader';
import { useSelector } from '@app/external/common/components/runtime-context';
import { useFeedMetadataSettings } from '@app/external/common/hooks/use-feed-metadata-settings';
import useLayoutProps from '@app/external/common/hooks/use-layout-props';
import useOnScreen from '@app/external/common/hooks/use-on-screen';
import { getIsPostListFullWidthEnabled } from '@app/external/common/selectors/app-settings-selectors';
import { getIsLazyPaginationEnabled } from '@app/external/common/selectors/pagination-selectors';
import { getLayoutConfig, type LayoutConfig } from '@app/external/common/services/layout-config';
import { getPostListCssVars } from '@app/external/common/services/post-list-css-vars';
import { type NormalizedPost } from '@app/external/common/types';
import MasonryWrapper from '../masonry-wrapper';
import styles from './post-list.scss';

type Props = {
  allPosts: NormalizedPost[];
  category?: NormalizedCategory;
  currentPagePosts: NormalizedPost[];
  entityCount: number;
  isLoading: boolean;
  layoutType?: Layout;
  loadMore?: LoadMoreProps['loadMore'];
  loadRest?: () => Promise<any>;
  page: number;
  pageSize: number;
  pageStart: LoadMoreProps['pageStart'];
  showCreatePostAction: boolean;
};

const PostList: React.FC<Props> = (props) => {
  const layoutProps = useLayoutProps({ layoutType: props.layoutType });
  const feedMetadataSettings = useFeedMetadataSettings(layoutProps);

  const isPostListFullWidthEnabled = useSelector(getIsPostListFullWidthEnabled);
  const layoutConfig = getLayoutConfig(layoutProps.layoutType!);
  const containerClassName = classNames(
    styles.container,
    isPostListFullWidthEnabled && styles.fullWidth,
    styles[layoutProps.layoutName],
    !feedMetadataSettings.isMetadataFooterVisible && styles.withoutFooter,
    'post-list',
  );

  return (
    <div className={containerClassName}>
      <MasonryWrapper isEnabled={layoutConfig.listComponentName === POST_LIST_COMPONENT_MASONRY}>
        <PostListLayout
          layoutConfig={layoutConfig}
          layoutName={layoutProps.layoutName}
          allPosts={props.allPosts}
          category={props.category}
          currentPagePosts={props.currentPagePosts}
          entityCount={props.entityCount}
          isLoading={props.isLoading}
          loadMore={props.loadMore}
          loadRest={props.loadRest}
          page={props.page}
          pageSize={props.pageSize}
          pageStart={props.pageStart}
          showCreatePostAction={props.showCreatePostAction}
        />
      </MasonryWrapper>
    </div>
  );
};

type PostListLayoutProps = Pick<
  Props,
  'currentPagePosts' | 'entityCount' | 'loadMore' | 'loadRest' | 'page' | 'pageSize' | 'pageStart'
> &
  Omit<PostListLayoutListProps, 'showLoader'>;

const PostListLayout: React.FC<PostListLayoutProps> = (props) => {
  const {
    allPosts,
    currentPagePosts,
    entityCount,
    isLoading,
    loadMore,
    loadRest,
    page,
    pageSize,
    pageStart,
  } = props;
  const isLazyPaginationEnabled = useSelector((state) => getIsLazyPaginationEnabled(state));

  if (loadRest && isLazyPaginationEnabled) {
    const totalRemainingPostCount = entityCount - (page - 1) * pageSize - currentPagePosts.length;
    const hasRemainingPosts = currentPagePosts.length < pageSize && totalRemainingPostCount > 0;

    return (
      <>
        <PostListLayoutList {...props} showLoader />

        {currentPagePosts.length > 0 && hasRemainingPosts && (
          <LoadRestObserver onVisible={loadRest} />
        )}
      </>
    );
  }

  if (loadMore) {
    return (
      <LoadMore
        loadMore={loadMore}
        loader={<Loader />}
        isLoading={isLoading}
        hasMore={allPosts.length < entityCount}
        pageStart={pageStart}
      >
        <PostListLayoutList {...props} />
      </LoadMore>
    );
  }

  return <PostListLayoutList {...props} showLoader />;
};

type PostListLayoutListProps = Pick<
  Props,
  'allPosts' | 'category' | 'isLoading' | 'showCreatePostAction'
> & {
  layoutName: LayoutName;
  layoutConfig: LayoutConfig;
  showLoader?: boolean;
};

const PostListLayoutList: React.FC<PostListLayoutListProps> = (props) => {
  const components = useComponents();

  const ListComponent = components[props.layoutConfig.listComponentName!];
  const ItemComponent = components[props.layoutConfig.itemComponentName!];

  return (
    <div style={getPostListCssVars()}>
      <ListComponent
        key="posts"
        posts={props.allPosts}
        showCreatePostAction={props.showCreatePostAction}
        type={props.layoutName}
        ItemComponent={ItemComponent}
        itemConfig={props.layoutConfig.itemConfig}
        category={props.category}
      />
      {props.showLoader && props.isLoading && <Loader dataHook="post-list__loader" key="loader" />}
    </div>
  );
};

type LoadRestObserverProps = {
  onVisible: () => void;
};

const LoadRestObserver: React.FC<LoadRestObserverProps> = ({ onVisible }) => {
  const { isEditor } = useEnvironment();
  const { isOnScreen, ref } = useOnScreen<HTMLDivElement>(undefined, isEditor);
  const doneRef = useRef(false);

  useEffect(() => {
    if (isOnScreen && doneRef.current === false) {
      onVisible();
      doneRef.current = true;
    }
  }, [isOnScreen, onVisible]);

  return isOnScreen ? null : <div ref={ref} />;
};

export default PostList;
