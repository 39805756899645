import { reduce, toString } from 'lodash';
import { extractHashtagsFromText } from '@wix/communities-blog-client-common';

export const shouldSearchByHashtag = (query) => {
  const hashtags = extractHashtagsFromText(query);
  return (
    query &&
    !reduce(
      hashtags,
      (prevValue, hashTag) => prevValue.replace(`#${hashTag}`, ''),
      toString(query),
    ).replace(/\s|,/g, '')
  );
};
