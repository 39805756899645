import { connect } from '../../../common/components/runtime-context';
import forDevice from '../../../common/hoc/for-device';
import SearchResultsDesktopList from './search-results-desktop-list';
import SearchResultsMobileList from './search-results-mobile-list';

const SearchResultsList = forDevice(SearchResultsMobileList, SearchResultsDesktopList);

const mapRuntimeToProps = (_state, _ownProps, actions) => ({
  onPostLikeClick: actions.incrementPostLikeCount,
});

export default connect(mapRuntimeToProps)(SearchResultsList);
