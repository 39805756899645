import { useTranslation } from '@wix/yoshi-flow-editor';
import { useSelector } from '../components/runtime-context';
import { getLanguage } from '../store/basic-params/basic-params-selectors';
import { getTranslations } from '../store/translations/translations-selectors';

const DEFAULT_TRANSLATION_NAMESPACE = 'translation';

export const useI18n = () => {
  const translations = useSelector(getTranslations);
  const language = useSelector(getLanguage);
  const { i18n } = useTranslation();
  i18n.addResourceBundle(
    language,
    DEFAULT_TRANSLATION_NAMESPACE,
    translations,
    true, // * Setting deep param to true will extend existing translations in that file.
    false, // * Setting overwrite to true it will overwrite existing translations in that file.
  );
};
