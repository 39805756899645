import React from 'react';
import classNames from 'classnames';
import CreatePostButton from '../create-post-button';
import styles from './create-post-button-mobile.scss';

const CreatePostButtonMobile = () => (
  <div className={classNames(styles.root, 'blog-card-background-color')} key="create-post">
    <CreatePostButton />
  </div>
);

export default CreatePostButtonMobile;
