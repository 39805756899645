import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../common/components/runtime-context';

export const BackButton = ({ children, className, goBack: handleClick }) => (
  <button className={className} onClick={handleClick}>
    {children}
  </button>
);

BackButton.propTypes = {
  goBack: PropTypes.func.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  goBack: actions.goBack,
});

export default connect(mapRuntimeToProps)(BackButton);
