import React from 'react';
import { useState, useEffect, type ReactNode } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSelector } from '../../../common/components/runtime-context';
import { isSeo as getIsSEo } from '../../../common/store/basic-params/basic-params-selectors';

type Props = {
  children: ReactNode;
  isEnabled: boolean;
};

const MasonryWrapper: React.FC<Props> = ({ children, isEnabled }) => {
  const isSeo = useSelector(getIsSEo);
  const { isSSR } = useEnvironment();
  const [isContentVisible, setIsContentVisible] = useState(!isEnabled || !isSSR || isSeo);

  useEffect(() => {
    setIsContentVisible(true);
  }, []);

  if (isContentVisible) {
    return <>{children}</>;
  }

  return null;
};

export default MasonryWrapper;
