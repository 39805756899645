import {
  createStylesParam,
  createStylesParams,
  StyleParamType,
  wixColorParam,
  wixFontParam,
  type IStyleParam,
} from '@wix/tpa-settings';
import {
  AUTHOR_INFO_TYPE_NAME_PICTURE,
  Layout,
  PaginationType,
  PgLayoutCardsRowTypeOptions,
  SideBySideLayout,
} from '@wix/communities-blog-client-common';
import {
  dangerousKeyTransformationOverride,
  getDefaultValueForRatingFont,
} from '@app/common/settings-components/helpers';
import {
  CategoryLabelType,
  CropType,
  HeightType,
  ImageAlignment,
  ImageRatio,
  VideoPlaybackSpeed,
  VideoPlayType,
} from '@app/common/settings-components/types';
import { AlignmentValue, type GetIStylesParams } from '@app/common/style-params';
import postStyleParams from '@app/components/Post/stylesParams';

const styleParamsForFullPostLayoutObject = {
  'post-textColor': postStyleParams.textParagraphColor,
  'post-pageFont': postStyleParams.textParagraphFont,
  'post-linkHashtagColor': postStyleParams.textLinksAndHashtagsColor,
  'post-quotesColor': postStyleParams.textQuoteColor,
  'post-quotesFont': postStyleParams.textQuoteFont,
  'post-titleColor': postStyleParams.textH1Color,
  'post-titleFont': postStyleParams.textH1Font,
  'post-headerTwoColor': postStyleParams.textH2Color,
  'post-headerTwoFont': postStyleParams.textH2Font,
  'post-headerThreeColor': postStyleParams.textH3Color,
  'post-headerThreeFont': postStyleParams.textH3Font,
  'post-headerFourColor': postStyleParams.textH4Color,
  'post-headerFourFont': postStyleParams.textH4Font,
  'post-headerFiveColor': postStyleParams.textH5Color,
  'post-headerFiveFont': postStyleParams.textH5Font,
  'post-headerSixColor': postStyleParams.textH6Color,
  'post-headerSixFont': postStyleParams.textH6Font,
  'post-cardBackgroundColor': postStyleParams.postBackgroundColor,
  'post-cardBorderWidth': postStyleParams.postBorderWidth,
  'post-cardBorderColor': postStyleParams.postBorderColor,
  'post-ratingUnfilledStarColor': postStyleParams.ratingsStarEmptyColor,
  'post-ratingFilledStarColor': postStyleParams.ratingsStarFilledColor,
  'post-ratingFont': postStyleParams.ratingsTextColor,
  'post-ratingFontColor': postStyleParams.ratingsTextFont,
};

const STYLE_PARAMS_FOR_POST_PAGE_LAYOUT: {
  [key: string]: Omit<IStyleParam, 'name'>;
} = Object.fromEntries(
  Object.entries(styleParamsForFullPostLayoutObject).map(([key, styleParam]) => [
    key,
    createStylesParam(key, styleParam),
  ]),
);

/** @deprecated Legacy, if 1 - blog-isAuthorPictureEnabled:false, if 0 - blog-isAuthorPictureEnabled:true  */
const showLegacyAuthorInfoType = createStylesParam('blog-authorInfoType', {
  type: StyleParamType.Number,
  getDefaultValue: () => AUTHOR_INFO_TYPE_NAME_PICTURE,
});

/** @deprecated Legacy, if 1 - blog-isAuthorPictureEnabled:false, if 0 - blog-isAuthorPictureEnabled:true  */
const showLegacyAuthorInfoTypeMobile = createStylesParam('blog-mobile-authorInfoType', {
  type: StyleParamType.Number,
  getDefaultValue: () => AUTHOR_INFO_TYPE_NAME_PICTURE,
});

const getDefaultValueTitleFont = wixFontParam('Page-title', { size: 22, htmlTag: 'h2' });
const getDefaultValueTitleFontOneColumn = wixFontParam('Page-title', { size: 26, htmlTag: 'h2' });
const getDefaultValueTitleColor = wixColorParam('color-5');
const getDefaultValueTitleColorForMagazineLayout = wixColorParam('color-1');
const getDefaultValueDescriptionFont = wixFontParam('Body-M', { size: 14 });
const getDefaultValueDescriptionFontOneColumn = wixFontParam('Body-M', { size: 16 });
const getDefaultValueDescriptionColor = wixColorParam('color-5');
const getDefaultValueDescriptionColorForMagazineLayout = wixColorParam('color-1');

const cssTitleColorAllPosts = createStylesParam<StyleParamType.Color>('post-homepage-titleColor', {
  type: StyleParamType.Color,
  getDefaultValue: getDefaultValueTitleColor,
});
const cssTitleColorCategory = createStylesParam<StyleParamType.Color>('post-category-titleColor', {
  type: StyleParamType.Color,
  getDefaultValue: getDefaultValueTitleColor,
});
const cssDescriptionColorAllPosts = createStylesParam<StyleParamType.Color>(
  'post-homepage-descriptionColor',
  { type: StyleParamType.Color, getDefaultValue: getDefaultValueDescriptionColor },
);
const cssDescriptionColorCategory = createStylesParam<StyleParamType.Color>(
  'post-category-descriptionColor',
  { type: StyleParamType.Color, getDefaultValue: getDefaultValueDescriptionColor },
);
const cssTitleColorLayoutMagazineAllPosts = createStylesParam<StyleParamType.Color>(
  'post-homepage-TOI-titleColor',
  {
    type: StyleParamType.Color,
    getDefaultValue: getDefaultValueTitleColorForMagazineLayout,
  },
);
const cssTitleColorLayoutMagazineCategory = createStylesParam<StyleParamType.Color>(
  'post-category-TOI-titleColor',
  {
    type: StyleParamType.Color,
    getDefaultValue: getDefaultValueTitleColorForMagazineLayout,
  },
);
const cssDescriptionColorLayoutMagazineAllPosts = createStylesParam<StyleParamType.Color>(
  'post-homepage-TOI-descriptionColor',
  {
    type: StyleParamType.Color,
    getDefaultValue: getDefaultValueDescriptionColorForMagazineLayout,
  },
);
const cssDescriptionColorLayoutMagazineCategory = createStylesParam<StyleParamType.Color>(
  'post-category-TOI-descriptionColor',
  {
    type: StyleParamType.Color,
    getDefaultValue: getDefaultValueDescriptionColorForMagazineLayout,
  },
);
const cssDescriptionFontAllPosts = createStylesParam<StyleParamType.Font>(
  'post-homepage-descriptionFont',
  { type: StyleParamType.Font, getDefaultValue: getDefaultValueDescriptionFont },
);
const cssDescriptionFontCategory = createStylesParam<StyleParamType.Font>(
  'post-category-descriptionFont',
  { type: StyleParamType.Font, getDefaultValue: getDefaultValueDescriptionFont },
);
const cssTitleFontCategory = createStylesParam<StyleParamType.Font>('post-category-titleFont', {
  type: StyleParamType.Font,
  getDefaultValue: getDefaultValueTitleFont,
});
const cssTitleFontAllPosts = createStylesParam<StyleParamType.Font>('post-homepage-titleFont', {
  type: StyleParamType.Font,
  getDefaultValue: getDefaultValueTitleFont,
});

const deriveStyleParamValue = <T extends StyleParamType>(
  styleParam: IStyleParam<T>,
): IStyleParam<T>['getDefaultValue'] => {
  return ({ getStyleParamValue, ...args }) => {
    return getStyleParamValue(styleParam, args);
  };
};

const getDerivedTitleFontAllPosts = deriveStyleParamValue(cssTitleFontAllPosts);
const getDerivedTitleFontCategory = deriveStyleParamValue(cssTitleFontCategory);
const getDerivedDescriptionFontAllPosts = deriveStyleParamValue(cssDescriptionFontAllPosts);
const getDerivedDescriptionFontCategory = deriveStyleParamValue(cssDescriptionFontCategory);
const getDerivedTitleColorAllPosts = deriveStyleParamValue(cssTitleColorAllPosts);
const getDerivedTitleColorCategory = deriveStyleParamValue(cssTitleColorCategory);
const getDerivedDescriptionColorAllPosts = deriveStyleParamValue(cssDescriptionColorAllPosts);
const getDerivedDescriptionColorCategory = deriveStyleParamValue(cssDescriptionColorCategory);
const getDerivedTitleColorLayoutMagazineAllPosts = deriveStyleParamValue(
  cssTitleColorLayoutMagazineAllPosts,
);
const getDerivedTitleColorLayoutMagazineCategory = deriveStyleParamValue(
  cssTitleColorLayoutMagazineCategory,
);
const getDerivedDescriptionColorLayoutMagazineAllPosts = deriveStyleParamValue(
  cssDescriptionColorLayoutMagazineAllPosts,
);
const getDerivedDescriptionColorLayoutMagazineCategory = deriveStyleParamValue(
  cssDescriptionColorLayoutMagazineCategory,
);

const STYLE_PARAMS = {
  // #region Display - Desktop
  showAuthorName: createStylesParam<StyleParamType.Boolean>('blog-isAuthorNameEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showAuthorPicture: createStylesParam<StyleParamType.Boolean>('blog-isAuthorPictureEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ getStyleParamValue }) => {
      return getStyleParamValue(showLegacyAuthorInfoType) === AUTHOR_INFO_TYPE_NAME_PICTURE;
    },
  }),
  showPostPublishDate: createStylesParam<StyleParamType.Boolean>('blog-isPostPublishDateEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showReadingTime: createStylesParam<StyleParamType.Boolean>('blog-isReadingTimeEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showCategoryLabel: createStylesParam<StyleParamType.Boolean>('blog-isCategoryLabelEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  }),
  /** Hidden on Studio Editor */
  showMenu: createStylesParam<StyleParamType.Boolean>('blogMenu-isEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  /** @deprecated Legacy, Classic only  */
  showMenuSearch: createStylesParam<StyleParamType.Boolean>('blogMenu-isSearchEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  /** Hidden on Studio Editor */
  showMenuLoginButton: createStylesParam<StyleParamType.Boolean>('widget-isLoginButtonEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: ({ isEditorX }) => (isEditorX ? false : true),
  }),
  showMenuCategoryLabels: createStylesParam<StyleParamType.Boolean>(
    'blogMenu-isCategoryLabelsEnabled',
    {
      type: StyleParamType.Boolean,
      getDefaultValue: () => true,
    },
  ),
  showMoreOptions: createStylesParam<StyleParamType.Boolean>('blog-isMoreOptionsMenuEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showDescription: createStylesParam<StyleParamType.Boolean>('blog-isPostDescriptionEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showPostRating: createStylesParam<StyleParamType.Boolean>('blog-isPostRatingEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showCommentCount: createStylesParam<StyleParamType.Boolean>('blog-isCommentCountEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showViewCount: createStylesParam<StyleParamType.Boolean>('blog-isViewCountEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showLikeCount: createStylesParam<StyleParamType.Boolean>('blog-isLikeCountEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  // #endregion
  // #region Display - Mobile
  isMobileDisplaySettingsEnabled: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isMobileDisplaySettingsEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => false,
    },
  ),
  showAuthorNameMobile: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isAuthorNameEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => true,
    },
  ),

  showAuthorPictureMobile: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isAuthorPictureEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: ({ getStyleParamValue }) => {
        return getStyleParamValue(showLegacyAuthorInfoTypeMobile) === AUTHOR_INFO_TYPE_NAME_PICTURE;
      },
    },
  ),
  showCategoryLabelMobile: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isCategoryLabelEnabled',
    {
      type: StyleParamType.Boolean,
      getDefaultValue: () => false,
    },
  ),
  showPostPublishDateMobile: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isPostPublishDateEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => true,
    },
  ),

  showReadingTimeMobile: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isReadingTimeEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => true,
    },
  ),
  /** Hidden on Studio Editor */
  showMenuMobile: createStylesParam<StyleParamType.Boolean>('blogMenu-mobile-isEnabled', {
    type: StyleParamType.Boolean,
    dangerousKeyTransformationOverride,
    getDefaultValue: () => true,
  }),
  /** @deprecated Legacy, Classic only  */
  showMenuSearchMobile: createStylesParam<StyleParamType.Boolean>(
    'blogMenu-mobile-isSearchEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => true,
    },
  ),
  /** Hidden on Studio Editor */
  showMenuLoginButtonMobile: createStylesParam<StyleParamType.Boolean>(
    'blogMenu-mobile-isLoginButtonEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: ({ isEditorX }) => (isEditorX ? false : true),
    },
  ),
  showMenuCategoryLabelsMobile: createStylesParam<StyleParamType.Boolean>(
    'blogMenu-mobile-isCategoryLabelsEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => true,
    },
  ),
  showMoreOptionsMobile: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isMoreOptionsMenuEnabled',
    {
      type: StyleParamType.Boolean,
      getDefaultValue: () => true,
    },
  ),
  showDescriptionMobile: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isPostDescriptionEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => true,
    },
  ),
  showPostRatingMobile: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isPostRatingEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => true,
    },
  ),
  showCommentCountMobile: createStylesParam<StyleParamType.Boolean>(
    'blog-mobile-isCommentCountEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => true,
    },
  ),
  showViewCountMobile: createStylesParam<StyleParamType.Boolean>('blog-mobile-isViewCountEnabled', {
    type: StyleParamType.Boolean,
    dangerousKeyTransformationOverride,
    getDefaultValue: () => true,
  }),
  showLikeCountMobile: createStylesParam<StyleParamType.Boolean>('blog-mobile-isLikeCountEnabled', {
    type: StyleParamType.Boolean,
    dangerousKeyTransformationOverride,
    getDefaultValue: () => true,
  }),
  // #endregion
  // #region Display - Social sharing - Desktop
  showShare: createStylesParam<StyleParamType.Boolean>('socialSharing-isEnabled', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  showShareIconFacebook: createStylesParam<StyleParamType.Boolean>('socialSharing-facebook', {
    type: StyleParamType.Boolean,
    dangerousKeyTransformationOverride,
    getDefaultValue: () => true,
  }),
  showShareIconX: createStylesParam<StyleParamType.Boolean>('socialSharing-twitter', {
    type: StyleParamType.Boolean,
    dangerousKeyTransformationOverride,
    getDefaultValue: () => true,
  }),
  showShareIconLinkedIn: createStylesParam<StyleParamType.Boolean>('socialSharing-linked-in', {
    type: StyleParamType.Boolean,
    dangerousKeyTransformationOverride,
    getDefaultValue: () => true,
  }),
  showShareIconLink: createStylesParam<StyleParamType.Boolean>('socialSharing-link', {
    type: StyleParamType.Boolean,
    dangerousKeyTransformationOverride,
    getDefaultValue: () => true,
  }),
  // #endregion
  // #region Display - Social sharing - Mobile
  showShareMobile: createStylesParam<StyleParamType.Boolean>('socialSharing-mobile-isEnabled', {
    type: StyleParamType.Boolean,
    dangerousKeyTransformationOverride,
    getDefaultValue: () => true,
  }),
  showShareIconFacebookMobile: createStylesParam<StyleParamType.Boolean>(
    'socialSharing-mobile-facebook',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => true,
    },
  ),
  showShareIconXMobile: createStylesParam<StyleParamType.Boolean>('socialSharing-mobile-twitter', {
    type: StyleParamType.Boolean,
    dangerousKeyTransformationOverride,
    getDefaultValue: () => true,
  }),
  showShareIconLinkedInMobile: createStylesParam<StyleParamType.Boolean>(
    'socialSharing-mobile-linked-in',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => true,
    },
  ),
  showShareIconLinkMobile: createStylesParam<StyleParamType.Boolean>('socialSharing-mobile-link', {
    type: StyleParamType.Boolean,
    dangerousKeyTransformationOverride,
    getDefaultValue: () => true,
  }),
  // #endregion
  // #region Layout - Desktop
  layoutTypeAllPostsFeed: createStylesParam<StyleParamType.Number>('layout-homepage-layoutType', {
    type: StyleParamType.Number,
    getDefaultValue: () => Layout.PgSideBySide,
  }),
  layoutTypeCategoryFeed: createStylesParam<StyleParamType.Number>('layout-category-layoutType', {
    type: StyleParamType.Number,
    getDefaultValue: () => Layout.PgSideBySide,
  }),
  layoutSideBySideCropTypeAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-side-by-side-imageCropType',
    { type: StyleParamType.Number, getDefaultValue: () => CropType.Crop },
  ),
  layoutSideBySideCropTypeCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-side-by-side-imageCropType',
    { type: StyleParamType.Number, getDefaultValue: () => CropType.Crop },
  ),
  layoutEditorialCropTypeAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-grid-imageCropType',
    { type: StyleParamType.Number, getDefaultValue: () => CropType.Crop },
  ),
  layoutEditorialCropTypeCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-grid-imageCropType',
    { type: StyleParamType.Number, getDefaultValue: () => CropType.Crop },
  ),
  layoutSideBySideImageRatioAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-side-by-side-imageRatio',
    { type: StyleParamType.Number, getDefaultValue: () => ImageRatio.FourByThree },
  ),
  layoutSideBySideImageRatioCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-side-by-side-imageRatio',
    { type: StyleParamType.Number, getDefaultValue: () => ImageRatio.FourByThree },
  ),
  layoutEditorialImageRatioAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-grid-imageRatio',
    { type: StyleParamType.Number, getDefaultValue: () => ImageRatio.FourByThree },
  ),
  layoutEditorialImageRatioCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-grid-imageRatio',
    { type: StyleParamType.Number, getDefaultValue: () => ImageRatio.FourByThree },
  ),
  layoutMagazineImageRatioAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-text-on-image-medium-imageRatio',
    { type: StyleParamType.Number, getDefaultValue: () => ImageRatio.FourByThree },
  ),
  layoutMagazineImageRatioCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-text-on-image-medium-imageRatio',
    { type: StyleParamType.Number, getDefaultValue: () => ImageRatio.FourByThree },
  ),
  layoutSideBySideImageAlignmentAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-side-by-side-imageAlignment',
    {
      type: StyleParamType.Number,
      getDefaultValue: () => ImageAlignment.End,
    },
  ),
  layoutSideBySideImageAlignmentCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-side-by-side-imageAlignment',
    {
      type: StyleParamType.Number,
      getDefaultValue: () => ImageAlignment.End,
    },
  ),
  /** Range: 20-60 */
  layoutSideBySideImageProportionsAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-side-by-side-imageProportions',
    { type: StyleParamType.Number, getDefaultValue: () => 50 },
  ),
  /** Range: 20-60 */
  layoutSideBySideImageProportionsCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-side-by-side-imageProportions',
    { type: StyleParamType.Number, getDefaultValue: () => 50 },
  ),
  textAlignment: createStylesParam<StyleParamType.Number>('contentAlignment', {
    type: StyleParamType.Number,
    getDefaultValue: () => AlignmentValue.Start,
  }),
  /** Range: 0-100 */
  layoutSideBySideSpacingAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-side-by-side-layoutSpacing',
    { type: StyleParamType.Number, getDefaultValue: () => 32 },
  ),
  /** Range: 0-100 */
  layoutSideBySideSpacingCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-side-by-side-layoutSpacing',
    { type: StyleParamType.Number, getDefaultValue: () => 32 },
  ),
  /** Range: 0-100 */
  layoutEditorialSpacingAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-grid-layoutSpacing',
    { type: StyleParamType.Number, getDefaultValue: () => 32 },
  ),
  /** Range: 0-100 */
  layoutEditorialSpacingCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-grid-layoutSpacing',
    { type: StyleParamType.Number, getDefaultValue: () => 32 },
  ),
  /** Range: 0-100 */
  layoutTiledSpacingAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-medium-layoutSpacing',
    { type: StyleParamType.Number, getDefaultValue: () => 32 },
  ),
  /** Range: 0-100 */
  layoutTiledSpacingCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-medium-layoutSpacing',
    { type: StyleParamType.Number, getDefaultValue: () => 32 },
  ),
  /** Range: 0-100 */
  layoutMagazineSpacingAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-text-on-image-medium-layoutSpacing',
    { type: StyleParamType.Number, getDefaultValue: () => 32 },
  ),
  /** Range: 0-100 */
  layoutMagazineSpacingCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-text-on-image-medium-layoutSpacing',
    { type: StyleParamType.Number, getDefaultValue: () => 32 },
  ),
  /** Range: 0-100 */
  layoutOneColumnSpacingAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-one-column-layoutSpacing',
    { type: StyleParamType.Number, getDefaultValue: () => 32 },
  ),
  /** Range: 0-100 */
  layoutOneColumnSpacingCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-one-column-layoutSpacing',
    { type: StyleParamType.Number, getDefaultValue: () => 32 },
  ),
  /** Range: 0-100 */
  layoutSideBySideMarginsAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-side-by-side-margins',
    { type: StyleParamType.Number, getDefaultValue: () => 20 },
  ),
  /** Range: 0-100 */
  layoutSideBySideMarginsCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-side-by-side-margins',
    { type: StyleParamType.Number, getDefaultValue: () => 20 },
  ),
  /** Range: 0-100 */
  layoutEditorialMarginsAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-grid-margins',
    { type: StyleParamType.Number, getDefaultValue: () => 20 },
  ),
  /** Range: 0-100 */
  layoutEditorialMarginsCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-grid-margins',
    { type: StyleParamType.Number, getDefaultValue: () => 20 },
  ),
  /** Range: 0-100 */
  layoutTiledMarginsAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-medium-margins',
    { type: StyleParamType.Number, getDefaultValue: () => 20 },
  ),
  /** Range: 0-100 */
  layoutTiledMarginsCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-medium-margins',
    { type: StyleParamType.Number, getDefaultValue: () => 20 },
  ),
  /** Range: 0-100 */
  layoutMagazineMarginsAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-text-on-image-medium-margins',
    { type: StyleParamType.Number, getDefaultValue: () => 20 },
  ),
  /** Range: 0-100 */
  layoutMagazineMarginsCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-text-on-image-medium-margins',
    { type: StyleParamType.Number, getDefaultValue: () => 20 },
  ),
  /** Range: 0-100 */
  layoutOneColumnMarginsAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-one-column-margins',
    { type: StyleParamType.Number, getDefaultValue: () => 20 },
  ),
  /** Range: 0-100 */
  layoutOneColumnMarginsCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-one-column-margins',
    { type: StyleParamType.Number, getDefaultValue: () => 20 },
  ),
  /** Range: 0-100 */
  layoutSideBySideHorizontalPaddingAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-side-by-side-sidesPadding',
    { type: StyleParamType.Number, getDefaultValue: () => 36 },
  ),
  /** Range: 0-100 */
  layoutSideBySideHorizontalPaddingCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-side-by-side-sidesPadding',
    { type: StyleParamType.Number, getDefaultValue: () => 36 },
  ),
  /** Range: 0-100 */
  layoutEditorialHorizontalPaddingAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-grid-sidesPadding',
    { type: StyleParamType.Number, getDefaultValue: () => 24 },
  ),
  /** Range: 0-100 */
  layoutEditorialHorizontalPaddingCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-grid-sidesPadding',
    { type: StyleParamType.Number, getDefaultValue: () => 24 },
  ),
  /** Range: 0-100 */
  layoutTiledHorizontalPaddingAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-medium-sidesPadding',
    { type: StyleParamType.Number, getDefaultValue: () => 24 },
  ),
  /** Range: 0-100 */
  layoutTiledHorizontalPaddingCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-medium-sidesPadding',
    { type: StyleParamType.Number, getDefaultValue: () => 24 },
  ),
  /** Range: 0-100 */
  layoutMagazineHorizontalPaddingAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-text-on-image-medium-sidesPadding',
    { type: StyleParamType.Number, getDefaultValue: () => 30 },
  ),
  /** Range: 0-100 */
  layoutMagazineHorizontalPaddingCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-text-on-image-medium-sidesPadding',
    { type: StyleParamType.Number, getDefaultValue: () => 30 },
  ),
  /** Range: 0-100 */
  layoutOneColumnHorizontalPaddingAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-one-column-sidesPadding',
    { type: StyleParamType.Number, getDefaultValue: () => 48 },
  ),
  /** Range: 0-100 */
  layoutOneColumnHorizontalPaddingCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-one-column-sidesPadding',
    { type: StyleParamType.Number, getDefaultValue: () => 48 },
  ),
  /** Range: 1-6 */
  layoutSideBySideTitleLineCountAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-side-by-side-titleLineCount',
    { type: StyleParamType.Number, getDefaultValue: () => 3 },
  ),
  /** Range: 1-6 */
  layoutSideBySideTitleLineCountCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-side-by-side-titleLineCount',
    { type: StyleParamType.Number, getDefaultValue: () => 3 },
  ),
  /** Range: 1-6 */
  layoutEditorialTitleLineCountAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-grid-titleLineCount',
    { type: StyleParamType.Number, getDefaultValue: () => 3 },
  ),
  /** Range: 1-6 */
  layoutEditorialTitleLineCountCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-grid-titleLineCount',
    { type: StyleParamType.Number, getDefaultValue: () => 3 },
  ),
  /** Range: 1-6 */
  layoutTiledTitleLineCountAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-medium-titleLineCount',
    { type: StyleParamType.Number, getDefaultValue: () => 3 },
  ),
  /** Range: 1-6 */
  layoutTiledTitleLineCountCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-medium-titleLineCount',
    { type: StyleParamType.Number, getDefaultValue: () => 3 },
  ),
  /** Range: 1-6 */
  layoutMagazineTitleLineCountAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-text-on-image-medium-titleLineCount',
    { type: StyleParamType.Number, getDefaultValue: () => 3 },
  ),
  /** Range: 1-6 */
  layoutMagazineTitleLineCountCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-text-on-image-medium-titleLineCount',
    { type: StyleParamType.Number, getDefaultValue: () => 3 },
  ),
  /** Range: 1-6 */
  layoutOneColumnTitleLineCountAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-one-column-titleLineCount',
    { type: StyleParamType.Number, getDefaultValue: () => 3 },
  ),
  /** Range: 1-6 */
  layoutOneColumnTitleLineCountCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-one-column-titleLineCount',
    { type: StyleParamType.Number, getDefaultValue: () => 3 },
  ),
  /** Range: 1-8 */
  layoutSideBySideDescriptionLineCountAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-side-by-side-descriptionLineCount',
    { type: StyleParamType.Number, getDefaultValue: () => 3 },
  ),
  /** Range: 1-8 */
  layoutSideBySideDescriptionLineCountCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-side-by-side-descriptionLineCount',
    { type: StyleParamType.Number, getDefaultValue: () => 3 },
  ),
  /** Range: 1-8 */
  layoutEditorialDescriptionLineCountAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-grid-descriptionLineCount',
    { type: StyleParamType.Number, getDefaultValue: () => 3 },
  ),
  /** Range: 1-8 */
  layoutEditorialDescriptionLineCountCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-grid-descriptionLineCount',
    { type: StyleParamType.Number, getDefaultValue: () => 3 },
  ),
  /** Range: 1-8 */
  layoutTiledDescriptionLineCountAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-medium-descriptionLineCount',
    { type: StyleParamType.Number, getDefaultValue: () => 3 },
  ),
  /** Range: 1-8 */
  layoutTiledDescriptionLineCountCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-medium-descriptionLineCount',
    { type: StyleParamType.Number, getDefaultValue: () => 3 },
  ),
  /** Range: 1-8 */
  layoutOneColumnDescriptionLineCountAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-one-column-descriptionLineCount',
    { type: StyleParamType.Number, getDefaultValue: () => 3 },
  ),
  /** Range: 1-8 */
  layoutOneColumnDescriptionLineCountCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-one-column-descriptionLineCount',
    { type: StyleParamType.Number, getDefaultValue: () => 3 },
  ),
  layoutEditorialHeightTypeAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-grid-contentHeightType',
    { type: StyleParamType.Number, getDefaultValue: () => HeightType.Manual },
  ),
  /** Range: 0-700 */
  layoutEditorialHeightAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-grid-contentHeight',
    { type: StyleParamType.Number, getDefaultValue: () => 350 },
  ),
  layoutEditorialHeightTypeCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-grid-contentHeightType',
    { type: StyleParamType.Number, getDefaultValue: () => HeightType.Manual },
  ),
  /** Range: 0-700 */
  layoutEditorialHeightCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-grid-contentHeight',
    { type: StyleParamType.Number, getDefaultValue: () => 350 },
  ),
  layoutTiledHeightTypeAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-medium-contentHeightType',
    { type: StyleParamType.Number, getDefaultValue: () => HeightType.Manual },
  ),
  /** Range: 0-700 */
  layoutTiledHeightAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-medium-contentHeight',
    { type: StyleParamType.Number, getDefaultValue: () => 350 },
  ),
  layoutTiledHeightTypeCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-medium-contentHeightType',
    { type: StyleParamType.Number, getDefaultValue: () => HeightType.Manual },
  ),
  /** Range: 0-700 */
  layoutTiledHeightCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-medium-contentHeight',
    { type: StyleParamType.Number, getDefaultValue: () => 350 },
  ),
  layoutOneColumnHeightTypeAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-one-column-contentHeightType',
    { type: StyleParamType.Number, getDefaultValue: () => HeightType.Manual },
  ),
  /** Range: 0-700 */
  layoutOneColumnHeightAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-one-column-contentHeight',
    { type: StyleParamType.Number, getDefaultValue: () => 350 },
  ),
  layoutOneColumnHeightTypeCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-one-column-contentHeightType',
    { type: StyleParamType.Number, getDefaultValue: () => HeightType.Manual },
  ),
  /** Range: 0-700 */
  layoutOneColumnHeightCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-one-column-contentHeight',
    { type: StyleParamType.Number, getDefaultValue: () => 350 },
  ),
  layoutEditorialCardsPerRowAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-grid-layoutCardsRowType',
    { type: StyleParamType.Number, getDefaultValue: () => PgLayoutCardsRowTypeOptions.FitToScreen },
  ),
  /** Range: 150-1000 */
  layoutEditorialCardsPerRowFitSizeAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-grid-layoutPostSize',
    { type: StyleParamType.Number, getDefaultValue: () => 292 },
  ),
  /** Range: 1-5 */
  layoutEditorialCardsPerRowFitCountAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-grid-layoutPostsPerRow',
    { type: StyleParamType.Number, getDefaultValue: () => 3 },
  ),
  layoutEditorialCardsPerRowCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-grid-layoutCardsRowType',
    { type: StyleParamType.Number, getDefaultValue: () => PgLayoutCardsRowTypeOptions.FitToScreen },
  ),
  /** Range: 150-1000 */
  layoutEditorialCardsPerRowFitSizeCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-grid-layoutPostSize',
    { type: StyleParamType.Number, getDefaultValue: () => 292 },
  ),
  /** Range: 1-5 */
  layoutEditorialCardsPerRowFitCountCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-grid-layoutPostsPerRow',
    { type: StyleParamType.Number, getDefaultValue: () => 3 },
  ),
  layoutTiledCardsPerRowAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-medium-layoutCardsRowType',
    { type: StyleParamType.Number, getDefaultValue: () => PgLayoutCardsRowTypeOptions.FitToScreen },
  ),
  /** Range: 150-1000 */
  layoutTiledCardsPerRowFitSizeAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-medium-layoutPostSize',
    { type: StyleParamType.Number, getDefaultValue: () => 454 },
  ),
  /** Range: 1-5 */
  layoutTiledCardsPerRowFitCountAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-medium-layoutPostsPerRow',
    { type: StyleParamType.Number, getDefaultValue: () => 3 },
  ),
  layoutTiledCardsPerRowCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-medium-layoutCardsRowType',
    { type: StyleParamType.Number, getDefaultValue: () => PgLayoutCardsRowTypeOptions.FitToScreen },
  ),
  /** Range: 150-1000 */
  layoutTiledCardsPerRowFitSizeCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-medium-layoutPostSize',
    { type: StyleParamType.Number, getDefaultValue: () => 454 },
  ),
  /** Range: 1-5 */
  layoutTiledCardsPerRowFitCountCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-medium-layoutPostsPerRow',
    { type: StyleParamType.Number, getDefaultValue: () => 3 },
  ),
  layoutMagazineCardsPerRowAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-text-on-image-medium-layoutCardsRowType',
    { type: StyleParamType.Number, getDefaultValue: () => PgLayoutCardsRowTypeOptions.FitToScreen },
  ),
  /** Range: 150-1000 */
  layoutMagazineCardsPerRowFitSizeAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-text-on-image-medium-layoutPostSize',
    { type: StyleParamType.Number, getDefaultValue: () => 454 },
  ),
  /** Range: 1-5 */
  layoutMagazineCardsPerRowFitCountAllPosts: createStylesParam<StyleParamType.Number>(
    'layout-homepage-pg-text-on-image-medium-layoutPostsPerRow',
    { type: StyleParamType.Number, getDefaultValue: () => 3 },
  ),
  layoutMagazineCardsPerRowCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-text-on-image-medium-layoutCardsRowType',
    { type: StyleParamType.Number, getDefaultValue: () => PgLayoutCardsRowTypeOptions.FitToScreen },
  ),
  /** Range: 150-1000 */
  layoutMagazineCardsPerRowFitSizeCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-text-on-image-medium-layoutPostSize',
    { type: StyleParamType.Number, getDefaultValue: () => 454 },
  ),
  /** Range: 1-5 */
  layoutMagazineCardsPerRowFitCountCategory: createStylesParam<StyleParamType.Number>(
    'layout-category-pg-text-on-image-medium-layoutPostsPerRow',
    { type: StyleParamType.Number, getDefaultValue: () => 3 },
  ),
  // #region Layout - Mobile
  layoutTypeAllPostsFeedMobile: createStylesParam<StyleParamType.Number>(
    'layout-mobile-homepage-layoutType',
    {
      type: StyleParamType.Number,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => Layout.OneColumnMobile,
    },
  ),
  layoutTypeCategoryFeedMobile: createStylesParam<StyleParamType.Number>(
    'layout-mobile-category-layoutType',
    {
      type: StyleParamType.Number,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => Layout.OneColumnMobile,
    },
  ),
  textAlignmentMobile: createStylesParam<StyleParamType.Number>('contentAlignmentMobile', {
    type: StyleParamType.Number,
    dangerousKeyTransformationOverride,
    getDefaultValue: () => AlignmentValue.Start,
  }),
  layoutSideBySideImageAlignmentAllPostsMobile: createStylesParam<StyleParamType.Number>(
    'blog-sideBySideLayoutImagePosition-homepage',
    {
      type: StyleParamType.Number,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => SideBySideLayout.ImageLeft,
    },
  ),
  layoutSideBySideImageAlignmentCategoryMobile: createStylesParam<StyleParamType.Number>(
    'blog-sideBySideLayoutImagePosition-category',
    {
      type: StyleParamType.Number,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => SideBySideLayout.ImageLeft,
    },
  ),
  paginationTypeAllPosts: createStylesParam<StyleParamType.Number>('pagination-homepage-type', {
    type: StyleParamType.Number,
    getDefaultValue: () => PaginationType.InfiniteScroll,
  }),
  paginationTypeCategory: createStylesParam<StyleParamType.Number>('pagination-category-type', {
    type: StyleParamType.Number,
    getDefaultValue: () => PaginationType.InfiniteScroll,
  }),
  /** Range: 2-24 (Full Post Layout: 1-3) */
  pageSizeAllPosts: createStylesParam<StyleParamType.Number>('pagination-homepage-pageSize', {
    type: StyleParamType.Number,
    getDefaultValue: () => 3,
  }),
  /** Range: 2-24 (Full Post Layout: 1-3) */
  pageSizeCategory: createStylesParam<StyleParamType.Number>('pagination-category-pageSize', {
    type: StyleParamType.Number,
    getDefaultValue: () => 3,
  }),
  // #endregion
  // #region Design - Navigation - Desktop
  navigationTextFont: createStylesParam<StyleParamType.Font>('navigation-font', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 14 }),
  }),
  navigationTextColor: createStylesParam<StyleParamType.Color>('navigation-textColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  }),
  navigationTextActiveColor: createStylesParam<StyleParamType.Color>('navigation-textColorActive', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  }),
  navigationBackgroundColor: createStylesParam<StyleParamType.Color>('navigation-backgroundColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  }),
  // #endregion
  // #region Design - Posts - Desktop
  // Side by Side
  titleFontLayoutSideBySideAllPosts: createStylesParam<StyleParamType.Font>(
    'post-pg-side-by-side-homepage-titleFont',
    { type: StyleParamType.Font, getDefaultValue: getDerivedTitleFontAllPosts },
  ),
  titleFontLayoutSideBySideCategory: createStylesParam<StyleParamType.Font>(
    'post-pg-side-by-side-category-titleFont',
    { type: StyleParamType.Font, getDefaultValue: getDerivedTitleFontCategory },
  ),
  titleColorLayoutSideBySideAllPosts: createStylesParam<StyleParamType.Color>(
    'post-pg-side-by-side-homepage-titleColor',
    { type: StyleParamType.Color, getDefaultValue: getDerivedTitleColorAllPosts },
  ),
  titleColorLayoutSideBySideCategory: createStylesParam<StyleParamType.Color>(
    'post-pg-side-by-side-category-titleColor',
    { type: StyleParamType.Color, getDefaultValue: getDerivedTitleColorCategory },
  ),
  descriptionFontLayoutSideBySideAllPosts: createStylesParam<StyleParamType.Font>(
    'post-pg-side-by-side-homepage-descriptionFont',
    { type: StyleParamType.Font, getDefaultValue: getDerivedDescriptionFontAllPosts },
  ),
  descriptionFontLayoutSideBySideCategory: createStylesParam<StyleParamType.Font>(
    'post-pg-side-by-side-category-descriptionFont',
    { type: StyleParamType.Font, getDefaultValue: getDerivedDescriptionFontCategory },
  ),
  descriptionColorLayoutSideBySideAllPosts: createStylesParam<StyleParamType.Color>(
    'post-pg-side-by-side-homepage-descriptionColor',
    { type: StyleParamType.Color, getDefaultValue: getDerivedDescriptionColorAllPosts },
  ),
  descriptionColorLayoutSideBySideCategory: createStylesParam<StyleParamType.Color>(
    'post-pg-side-by-side-category-descriptionColor',
    { type: StyleParamType.Color, getDefaultValue: getDerivedDescriptionColorCategory },
  ),
  // Editorial
  titleFontLayoutEditorialAllPosts: createStylesParam<StyleParamType.Font>(
    'post-pg-grid-homepage-titleFont',
    { type: StyleParamType.Font, getDefaultValue: getDerivedTitleFontAllPosts },
  ),
  titleFontLayoutEditorialCategory: createStylesParam<StyleParamType.Font>(
    'post-pg-grid-category-titleFont',
    { type: StyleParamType.Font, getDefaultValue: getDerivedTitleFontCategory },
  ),
  titleColorLayoutEditorialAllPosts: createStylesParam<StyleParamType.Color>(
    'post-pg-grid-homepage-titleColor',
    { type: StyleParamType.Color, getDefaultValue: getDerivedTitleColorAllPosts },
  ),
  titleColorLayoutEditorialCategory: createStylesParam<StyleParamType.Color>(
    'post-pg-grid-category-titleColor',
    { type: StyleParamType.Color, getDefaultValue: getDerivedTitleColorCategory },
  ),
  descriptionFontLayoutEditorialAllPosts: createStylesParam<StyleParamType.Font>(
    'post-pg-grid-homepage-descriptionFont',
    { type: StyleParamType.Font, getDefaultValue: getDerivedDescriptionFontAllPosts },
  ),
  descriptionFontLayoutEditorialCategory: createStylesParam<StyleParamType.Font>(
    'post-pg-grid-category-descriptionFont',
    { type: StyleParamType.Font, getDefaultValue: getDerivedDescriptionFontCategory },
  ),
  descriptionColorLayoutEditorialAllPosts: createStylesParam<StyleParamType.Color>(
    'post-pg-grid-homepage-descriptionColor',
    { type: StyleParamType.Color, getDefaultValue: getDerivedDescriptionColorAllPosts },
  ),
  descriptionColorLayoutEditorialCategory: createStylesParam<StyleParamType.Color>(
    'post-pg-grid-category-descriptionColor',
    { type: StyleParamType.Color, getDefaultValue: getDerivedDescriptionColorCategory },
  ),
  // Tiled
  titleFontLayoutTiledAllPosts: createStylesParam<StyleParamType.Font>(
    'post-pg-medium-homepage-titleFont',
    { type: StyleParamType.Font, getDefaultValue: getDerivedTitleFontAllPosts },
  ),
  titleFontLayoutTiledCategory: createStylesParam<StyleParamType.Font>(
    'post-pg-medium-category-titleFont',
    { type: StyleParamType.Font, getDefaultValue: getDerivedTitleFontCategory },
  ),
  titleColorLayoutTiledAllPosts: createStylesParam<StyleParamType.Color>(
    'post-pg-medium-homepage-titleColor',
    { type: StyleParamType.Color, getDefaultValue: getDerivedTitleColorAllPosts },
  ),
  titleColorLayoutTiledCategory: createStylesParam<StyleParamType.Color>(
    'post-pg-medium-category-titleColor',
    { type: StyleParamType.Color, getDefaultValue: getDerivedTitleColorCategory },
  ),
  descriptionFontLayoutTiledAllPosts: createStylesParam<StyleParamType.Font>(
    'post-pg-medium-homepage-descriptionFont',
    { type: StyleParamType.Font, getDefaultValue: getDerivedDescriptionFontAllPosts },
  ),
  descriptionFontLayoutTiledCategory: createStylesParam<StyleParamType.Font>(
    'post-pg-medium-category-descriptionFont',
    { type: StyleParamType.Font, getDefaultValue: getDerivedDescriptionFontCategory },
  ),
  descriptionColorLayoutTiledAllPosts: createStylesParam<StyleParamType.Color>(
    'post-pg-medium-homepage-descriptionColor',
    { type: StyleParamType.Color, getDefaultValue: getDerivedDescriptionColorAllPosts },
  ),
  descriptionColorLayoutTiledCategory: createStylesParam<StyleParamType.Color>(
    'post-pg-medium-category-descriptionColor',
    { type: StyleParamType.Color, getDefaultValue: getDerivedDescriptionColorCategory },
  ),
  // Magazine
  titleFontLayoutMagazineAllPosts: createStylesParam<StyleParamType.Font>(
    'post-pg-text-on-image-medium-homepage-titleFont',
    { type: StyleParamType.Font, getDefaultValue: getDerivedTitleFontAllPosts },
  ),
  titleFontLayoutMagazineCategory: createStylesParam<StyleParamType.Font>(
    'post-pg-text-on-image-medium-category-titleFont',
    { type: StyleParamType.Font, getDefaultValue: getDerivedTitleFontCategory },
  ),
  titleColorLayoutMagazineAllPosts: createStylesParam<StyleParamType.Color>(
    'post-pg-text-on-image-medium-homepage-titleColor',
    { type: StyleParamType.Color, getDefaultValue: getDerivedTitleColorLayoutMagazineAllPosts },
  ),
  titleColorLayoutMagazineCategory: createStylesParam<StyleParamType.Color>(
    'post-pg-text-on-image-medium-category-titleColor',
    { type: StyleParamType.Color, getDefaultValue: getDerivedTitleColorLayoutMagazineCategory },
  ),
  descriptionFontLayoutMagazineAllPosts: createStylesParam<StyleParamType.Font>(
    'post-pg-text-on-image-medium-homepage-descriptionFont',
    { type: StyleParamType.Font, getDefaultValue: getDerivedDescriptionFontAllPosts },
  ),
  descriptionFontLayoutMagazineCategory: createStylesParam<StyleParamType.Font>(
    'post-pg-text-on-image-medium-category-descriptionFont',
    { type: StyleParamType.Font, getDefaultValue: getDerivedDescriptionFontCategory },
  ),
  descriptionColorLayoutMagazineAllPosts: createStylesParam<StyleParamType.Color>(
    'post-pg-text-on-image-medium-homepage-descriptionColor',
    {
      type: StyleParamType.Color,
      getDefaultValue: getDerivedDescriptionColorLayoutMagazineAllPosts,
    },
  ),
  descriptionColorLayoutMagazineCategory: createStylesParam<StyleParamType.Color>(
    'post-pg-text-on-image-medium-category-descriptionColor',
    {
      type: StyleParamType.Color,
      getDefaultValue: getDerivedDescriptionColorLayoutMagazineCategory,
    },
  ),
  // One Column
  titleFontLayoutOneColumnAllPosts: createStylesParam<StyleParamType.Font>(
    'post-pg-one-column-homepage-titleFont',
    { type: StyleParamType.Font, getDefaultValue: getDefaultValueTitleFontOneColumn },
  ),
  titleFontLayoutOneColumnCategory: createStylesParam<StyleParamType.Font>(
    'post-pg-one-column-category-titleFont',
    { type: StyleParamType.Font, getDefaultValue: getDefaultValueTitleFontOneColumn },
  ),
  titleColorLayoutOneColumnAllPosts: createStylesParam<StyleParamType.Color>(
    'post-pg-one-column-homepage-titleColor',
    { type: StyleParamType.Color, getDefaultValue: getDerivedTitleColorAllPosts },
  ),
  titleColorLayoutOneColumnCategory: createStylesParam<StyleParamType.Color>(
    'post-pg-one-column-category-titleColor',
    { type: StyleParamType.Color, getDefaultValue: getDerivedTitleColorCategory },
  ),
  descriptionFontLayoutOneColumnAllPosts: createStylesParam<StyleParamType.Font>(
    'post-pg-one-column-homepage-descriptionFont',
    { type: StyleParamType.Font, getDefaultValue: getDefaultValueDescriptionFontOneColumn },
  ),
  descriptionFontLayoutOneColumnCategory: createStylesParam<StyleParamType.Font>(
    'post-pg-one-column-category-descriptionFont',
    { type: StyleParamType.Font, getDefaultValue: getDefaultValueDescriptionFontOneColumn },
  ),
  descriptionColorLayoutOneColumnAllPosts: createStylesParam<StyleParamType.Color>(
    'post-pg-one-column-homepage-descriptionColor',
    { type: StyleParamType.Color, getDefaultValue: getDerivedDescriptionColorAllPosts },
  ),
  descriptionColorLayoutOneColumnCategory: createStylesParam<StyleParamType.Color>(
    'post-pg-one-column-category-descriptionColor',
    { type: StyleParamType.Color, getDefaultValue: getDerivedDescriptionColorCategory },
  ),
  //
  cssTitleFontAllPosts,
  cssTitleFontCategory,
  cssDescriptionFontAllPosts,
  cssDescriptionFontCategory,
  cssTitleColorAllPosts,
  cssTitleColorCategory,
  cssDescriptionColorAllPosts,
  cssDescriptionColorCategory,
  cssTitleColorLayoutMagazineAllPosts,
  cssTitleColorLayoutMagazineCategory,
  cssDescriptionColorLayoutMagazineAllPosts,
  cssDescriptionColorLayoutMagazineCategory,
  titleColorHoverAllPosts: createStylesParam<StyleParamType.Color>(
    'post-homepage-linkHashtagColor',
    { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-8') },
  ),
  titleColorHoverCategory: createStylesParam<StyleParamType.Color>(
    'post-category-linkHashtagColor',
    { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-8') },
  ),
  borderColorAllPosts: createStylesParam<StyleParamType.Color>('post-homepage-borderColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.75),
  }),
  borderColorCategory: createStylesParam<StyleParamType.Color>('post-category-borderColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.75),
  }),
  borderWidthAllPosts: createStylesParam<StyleParamType.Number>('post-homepage-borderWidth', {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  }),
  borderWidthCategory: createStylesParam<StyleParamType.Number>('post-category-borderWidth', {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  }),
  borderRadiusAllPosts: createStylesParam<StyleParamType.Number>('post-homepage-postRadius', {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  }),
  borderRadiusCategory: createStylesParam<StyleParamType.Number>('post-category-postRadius', {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  }),
  overlayBackgroundColorAllPosts: createStylesParam<StyleParamType.Color>(
    'post-homepage-overlayColor',
    {
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-5', 0.55),
    },
  ),
  overlayBackgroundColorCategory: createStylesParam<StyleParamType.Color>(
    'post-category-overlayColor',
    {
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-5', 0.55),
    },
  ),
  backgroundColorAllPosts: createStylesParam<StyleParamType.Color>(
    'post-homepage-backgroundColor',
    { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-1') },
  ),
  backgroundColorCategory: createStylesParam<StyleParamType.Color>(
    'post-category-backgroundColor',
    { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-1') },
  ),
  areaBackgroundColorAllPosts: createStylesParam<StyleParamType.Color>('post-appBackgroundColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  }),
  areaBackgroundColorCategory: createStylesParam<StyleParamType.Color>(
    'post-appBackgroundCategoryColor',
    { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-1') },
  ),
  // #endregion
  // #region Design - Posts - Mobile
  // One Column
  titleFontSizeLayoutOneColumnAllPostsMobile: createStylesParam<StyleParamType.Number>(
    'post-one-column-mobile-homepage-titleFontSize',
    {
      type: StyleParamType.Number,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => 22,
    },
  ),
  titleFontSizeLayoutOneColumnCategoryMobile: createStylesParam<StyleParamType.Number>(
    'post-one-column-mobile-category-titleFontSize',
    {
      type: StyleParamType.Number,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => 22,
    },
  ),
  descriptionFontSizeLayoutOneColumnAllPostsMobile: createStylesParam<StyleParamType.Number>(
    'post-one-column-mobile-homepage-descriptionFontSize',
    {
      type: StyleParamType.Number,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => 14,
    },
  ),
  descriptionFontSizeLayoutOneColumnCategoryMobile: createStylesParam<StyleParamType.Number>(
    'post-one-column-mobile-category-descriptionFontSize',
    {
      type: StyleParamType.Number,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => 14,
    },
  ),
  // Side By Side
  titleFontSizeLayoutSideBySideAllPostsMobile: createStylesParam<StyleParamType.Number>(
    'post-side-by-side-mobile-homepage-titleFontSize',
    {
      type: StyleParamType.Number,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => 14,
    },
  ),
  titleFontSizeLayoutSideBySideCategoryMobile: createStylesParam<StyleParamType.Number>(
    'post-side-by-side-mobile-category-titleFontSize',
    {
      type: StyleParamType.Number,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => 14,
    },
  ),
  // Magazine
  titleFontSizeLayoutMagazineAllPostsMobile: createStylesParam<StyleParamType.Number>(
    'post-text-on-image-mobile-homepage-titleFontSize',
    {
      type: StyleParamType.Number,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => 22,
    },
  ),
  titleFontSizeLayoutMagazineCategoryMobile: createStylesParam<StyleParamType.Number>(
    'post-text-on-image-mobile-category-titleFontSize',
    {
      type: StyleParamType.Number,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => 22,
    },
  ),
  // #endregion
  // #region Design - Category labels - Desktop
  categoryLabelType: createStylesParam<StyleParamType.Number>('category-label-layoutType', {
    type: StyleParamType.Number,
    getDefaultValue: () => CategoryLabelType.Text,
  }),
  categoryLabelTextFont: createStylesParam<StyleParamType.Font>('category-label-text-textFont', {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 14, lineHeight: 14 * 1.4 }),
  }),
  categoryLabelTextColor: createStylesParam<StyleParamType.Color>('category-label-text-textColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  }),
  categoryLabelTextColorHover: createStylesParam<StyleParamType.Color>(
    'category-label-text-textColorHover',
    { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-8', 0.7) },
  ),
  categoryLabelTextUppercase: createStylesParam<StyleParamType.Boolean>(
    'category-label-text-uppercase',
    { type: StyleParamType.Boolean, getDefaultValue: () => false },
  ),
  /** Range: 0-100 */
  categoryLabelButtonHorizontalPadding: createStylesParam<StyleParamType.Number>(
    'category-label-button-horizontalPadding',
    { type: StyleParamType.Number, getDefaultValue: () => 12 },
  ),
  /** Range: 0-100 */
  categoryLabelButtonVerticalPadding: createStylesParam<StyleParamType.Number>(
    'category-label-button-verticalPadding',
    { type: StyleParamType.Number, getDefaultValue: () => 6 },
  ),
  categoryLabelButtonFont: createStylesParam<StyleParamType.Font>(
    'category-label-button-textFont',
    {
      type: StyleParamType.Font,
      getDefaultValue: wixFontParam('Body-M', { size: 14, lineHeight: 14 * 1.4 }),
    },
  ),
  categoryLabelButtonTextColor: createStylesParam<StyleParamType.Color>(
    'category-label-button-textColor',
    { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-1') },
  ),
  categoryLabelButtonTextColorHover: createStylesParam<StyleParamType.Color>(
    'category-label-button-textColorHover',
    { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-1') },
  ),
  categoryLabelButtonUppercase: createStylesParam<StyleParamType.Boolean>(
    'category-label-button-uppercase',
    { type: StyleParamType.Boolean, getDefaultValue: () => false },
  ),
  categoryLabelButtonBackgroundColor: createStylesParam<StyleParamType.Color>(
    'category-label-button-backgroundColor',
    { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-8') },
  ),
  categoryLabelButtonBackgroundColorHover: createStylesParam<StyleParamType.Color>(
    'category-label-button-backgroundColorHover',
    { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-8', 0.7) },
  ),
  /** Range: 0-100 */
  categoryLabelButtonCornerRadius: createStylesParam<StyleParamType.Number>(
    'category-label-button-cornerRadius',
    { type: StyleParamType.Number, getDefaultValue: () => 0 },
  ),
  categoryLabelButtonBorderColor: createStylesParam<StyleParamType.Color>(
    'category-label-button-borderColor',
    { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-8') },
  ),
  categoryLabelButtonBorderColorHover: createStylesParam<StyleParamType.Color>(
    'category-label-button-borderColorHover',
    { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-8', 0.7) },
  ),
  /** Range: 0-10 */
  categoryLabelButtonBorderWidth: createStylesParam<StyleParamType.Number>(
    'category-label-button-borderWidth',
    { type: StyleParamType.Number, getDefaultValue: () => 0 },
  ),
  // #endregion
  // #region Design - Category labels - Mobile
  categoryLabelMobileSettingsEnabled: createStylesParam<StyleParamType.Boolean>(
    'category-label-isMobileDesignSettingsEnabled',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => false,
    },
  ),
  categoryLabelTypeMobile: createStylesParam<StyleParamType.Number>(
    'category-label-mobile-layoutType',
    {
      type: StyleParamType.Number,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => CategoryLabelType.Text,
    },
  ),
  categoryLabelTextFontMobile: createStylesParam<StyleParamType.Font>(
    'category-label-text-mobile-textFont',
    {
      type: StyleParamType.Font,
      dangerousKeyTransformationOverride,
      getDefaultValue: wixFontParam('Body-M', { size: 14, lineHeight: 14 * 1.4 }),
    },
  ),
  categoryLabelTextColorMobile: createStylesParam<StyleParamType.Color>(
    'category-label-text-mobile-textColor',
    {
      type: StyleParamType.Color,
      dangerousKeyTransformationOverride,
      getDefaultValue: wixColorParam('color-8'),
    },
  ),
  categoryLabelTextUppercaseMobile: createStylesParam<StyleParamType.Boolean>(
    'category-label-text-mobile-uppercase',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => false,
    },
  ),
  /** Range: 0-100 */
  categoryLabelButtonHorizontalPaddingMobile: createStylesParam<StyleParamType.Number>(
    'category-label-button-mobile-horizontalPadding',
    { type: StyleParamType.Number, dangerousKeyTransformationOverride, getDefaultValue: () => 12 },
  ),
  /** Range: 0-100 */
  categoryLabelButtonVerticalPaddingMobile: createStylesParam<StyleParamType.Number>(
    'category-label-button-mobile-verticalPadding',
    { type: StyleParamType.Number, dangerousKeyTransformationOverride, getDefaultValue: () => 6 },
  ),
  categoryLabelButtonFontMobile: createStylesParam<StyleParamType.Font>(
    'category-label-button-mobile-textFont',
    {
      type: StyleParamType.Font,
      dangerousKeyTransformationOverride,
      getDefaultValue: wixFontParam('Body-M', { size: 14, lineHeight: 14 * 1.4 }),
    },
  ),
  categoryLabelButtonTextColorMobile: createStylesParam<StyleParamType.Color>(
    'category-label-button-mobile-textColor',
    {
      type: StyleParamType.Color,
      dangerousKeyTransformationOverride,
      getDefaultValue: wixColorParam('color-1'),
    },
  ),
  categoryLabelButtonUppercaseMobile: createStylesParam<StyleParamType.Boolean>(
    'category-label-button-mobile-uppercase',
    {
      type: StyleParamType.Boolean,
      dangerousKeyTransformationOverride,
      getDefaultValue: () => false,
    },
  ),
  categoryLabelButtonBackgroundColorMobile: createStylesParam<StyleParamType.Color>(
    'category-label-button-mobile-backgroundColor',
    {
      type: StyleParamType.Color,
      dangerousKeyTransformationOverride,
      getDefaultValue: wixColorParam('color-8'),
    },
  ),
  /** Range: 0-100 */
  categoryLabelButtonCornerRadiusMobile: createStylesParam<StyleParamType.Number>(
    'category-label-button-mobile-cornerRadius',
    { type: StyleParamType.Number, dangerousKeyTransformationOverride, getDefaultValue: () => 0 },
  ),
  categoryLabelButtonBorderColorMobile: createStylesParam<StyleParamType.Color>(
    'category-label-button-mobile-borderColor',
    {
      type: StyleParamType.Color,
      dangerousKeyTransformationOverride,
      getDefaultValue: wixColorParam('color-8'),
    },
  ),
  /** Range: 0-10 */
  categoryLabelButtonBorderWidthMobile: createStylesParam<StyleParamType.Number>(
    'category-label-button-mobile-borderWidth',
    { type: StyleParamType.Number, dangerousKeyTransformationOverride, getDefaultValue: () => 0 },
  ),
  // #endregion
  // #region Design - Buttons - Desktop
  buttonTextColor: createStylesParam<StyleParamType.Color>('button-textColor', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  }),
  buttonBackgroundColor: createStylesParam<StyleParamType.Color>('button-color', {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  }),
  // #endregion
  // #region Design - Ratings - Desktop
  ratingsStarFilledColorAllPosts: createStylesParam<StyleParamType.Color>(
    'post-homepage-ratingFilledStarColor',
    { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-8') },
  ),
  ratingsStarFilledColorCategory: createStylesParam<StyleParamType.Color>(
    'post-category-ratingFilledStarColor',
    { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-8') },
  ),
  ratingsStarEmptyColorAllPosts: createStylesParam<StyleParamType.Color>(
    'post-homepage-ratingUnfilledStarColor',
    { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-3') },
  ),
  ratingsStarEmptyColorCategory: createStylesParam<StyleParamType.Color>(
    'post-category-ratingUnfilledStarColor',
    { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-3') },
  ),
  ratingsLayoutMagazineStarFilledColorAllPosts: createStylesParam<StyleParamType.Color>(
    'post-homepage-TOI-ratingFilledStarColor',
    { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-1') },
  ),
  ratingsLayoutMagazineStarFilledColorCategory: createStylesParam<StyleParamType.Color>(
    'post-category-TOI-ratingFilledStarColor',
    { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-1') },
  ),
  ratingsLayoutMagazineStarEmptyColorAllPosts: createStylesParam<StyleParamType.Color>(
    'post-homepage-TOI-ratingUnfilledStarColor',
    { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-3') },
  ),
  ratingsLayoutMagazineStarEmptyColorCategory: createStylesParam<StyleParamType.Color>(
    'post-category-TOI-ratingUnfilledStarColor',
    { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-3') },
  ),
  ratingsFontColorAllPosts: createStylesParam<StyleParamType.Color>(
    'post-homepage-ratingFontColor',
    {
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-5'),
    },
  ),
  ratingsFontColorCategory: createStylesParam<StyleParamType.Color>(
    'post-category-ratingFontColor',
    {
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-5'),
    },
  ),
  cssRatingsFontAllPosts: createStylesParam<StyleParamType.Font>('post-homepage-ratingFont', {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultValueForRatingFont,
  }),
  cssRatingsFontCategory: createStylesParam<StyleParamType.Font>('post-category-ratingFont', {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultValueForRatingFont,
  }),
  ratingsLayoutMagazineFontColorAllPosts: createStylesParam<StyleParamType.Color>(
    'post-homepage-TOI-ratingFontColor',
    { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-1') },
  ),
  ratingsLayoutMagazineFontColorCategory: createStylesParam<StyleParamType.Color>(
    'post-category-TOI-ratingFontColor',
    { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-1') },
  ),
  ratingsLayoutSideBySideFontAllPosts: createStylesParam<StyleParamType.Font>(
    'post-pg-side-by-side-homepage-ratingFont',
    { type: StyleParamType.Font, getDefaultValue: getDefaultValueForRatingFont },
  ),
  ratingsLayoutSideBySideFontCategory: createStylesParam<StyleParamType.Font>(
    'post-pg-side-by-side-category-ratingFont',
    { type: StyleParamType.Font, getDefaultValue: getDefaultValueForRatingFont },
  ),
  ratingsLayoutEditorialFontAllPosts: createStylesParam<StyleParamType.Font>(
    'post-pg-grid-homepage-ratingFont',
    { type: StyleParamType.Font, getDefaultValue: getDefaultValueForRatingFont },
  ),
  ratingsLayoutEditorialFontCategory: createStylesParam<StyleParamType.Font>(
    'post-pg-grid-category-ratingFont',
    { type: StyleParamType.Font, getDefaultValue: getDefaultValueForRatingFont },
  ),
  ratingsLayoutTiledFontAllPosts: createStylesParam<StyleParamType.Font>(
    'post-pg-medium-homepage-ratingFont',
    { type: StyleParamType.Font, getDefaultValue: getDefaultValueForRatingFont },
  ),
  ratingsLayoutTiledFontCategory: createStylesParam<StyleParamType.Font>(
    'post-pg-medium-category-ratingFont',
    { type: StyleParamType.Font, getDefaultValue: getDefaultValueForRatingFont },
  ),
  ratingsLayoutMagazineFontAllPosts: createStylesParam<StyleParamType.Font>(
    'post-pg-text-on-image-medium-homepage-ratingFont',
    { type: StyleParamType.Font, getDefaultValue: getDefaultValueForRatingFont },
  ),
  ratingsLayoutMagazineFontCategory: createStylesParam<StyleParamType.Font>(
    'post-pg-text-on-image-medium-category-ratingFont',
    { type: StyleParamType.Font, getDefaultValue: getDefaultValueForRatingFont },
  ),
  ratingsLayoutOneColumnFontAllPosts: createStylesParam<StyleParamType.Font>(
    'post-pg-one-column-homepage-ratingFont',
    { type: StyleParamType.Font, getDefaultValue: getDefaultValueForRatingFont },
  ),
  ratingsLayoutOneColumnFontCategory: createStylesParam<StyleParamType.Font>(
    'post-pg-one-column-category-ratingFont',
    { type: StyleParamType.Font, getDefaultValue: getDefaultValueForRatingFont },
  ),
  // #endregion
  // #region Design - Video settings - Desktop
  videoSettingsPlayType: createStylesParam<StyleParamType.Number>('feed-page-video-play', {
    type: StyleParamType.Number,
    getDefaultValue: () => VideoPlayType.OnHover,
  }),
  videoSettingsSoundEnabled: createStylesParam<StyleParamType.Boolean>('feed-page-video-sound', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  }),
  videoSettingsPlaybackSpeed: createStylesParam<StyleParamType.Number>(
    'feed-page-video-playbackSpeed',
    { type: StyleParamType.Number, getDefaultValue: () => VideoPlaybackSpeed.Normal },
  ),
  videoSettingsLoopEnabled: createStylesParam<StyleParamType.Boolean>('feed-page-video-loop', {
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  }),
  videoSettingsShowPlayButton: createStylesParam<StyleParamType.Boolean>(
    'feed-page-video-showPlayButton',
    { type: StyleParamType.Boolean, getDefaultValue: () => true },
  ),
  // #endregion
} as const satisfies {
  [key: string]: Omit<IStyleParam, 'name'>;
};

export type IStylesParams = GetIStylesParams<typeof STYLE_PARAMS>;

export default createStylesParams<IStylesParams>({
  ...STYLE_PARAMS_FOR_POST_PAGE_LAYOUT,
  ...STYLE_PARAMS,
});
