import React from 'react';
import classNames from 'classnames';
import { flowRight, omit } from 'lodash';
import PropTypes from 'prop-types';
import ArrowBackIcon from '../../../common/components/icons/arrow-back-icon';
import withFontClassName from '../../../common/hoc/with-font-class-name';
import withTranslate from '../../../common/hoc/with-translate';
import BackButton from '../../containers/back-button';
import styles from './header-back-button.scss';

const HeaderBackButton = ({
  component: ContainerComponent = BackButton,
  arrow,
  text = 'header-back-button.back',
  t,
  contentFontClassName,
  ...rest
}) => {
  const iconClassName = classNames(styles.icon, 'blog-button-primary-icon-fill');
  const containerComponentProps =
    ContainerComponent === 'button'
      ? omit(rest, ['titleFontClassName', 'dispatch', 'tReady', 'lng'])
      : rest;

  const wrapperClassName = classNames(styles.wrapper, contentFontClassName);
  const labelClassName = classNames(styles.label, 'blog-button-primary-text-color');

  return (
    <ContainerComponent
      className={classNames(styles.button, 'header-back-button')}
      {...containerComponentProps}
    >
      <span className={wrapperClassName}>
        {arrow ? <ArrowBackIcon className={iconClassName} /> : null}

        <span className={labelClassName}>{text && t(text)}</span>
      </span>
    </ContainerComponent>
  );
};

HeaderBackButton.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  arrow: PropTypes.bool,
  text: PropTypes.string,
  t: PropTypes.func,
  contentFontClassName: PropTypes.string,
};

export default flowRight(withFontClassName, withTranslate)(HeaderBackButton);
